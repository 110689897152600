<template>
  <div v-if="$store.state.tournament.currentMatch" class="round-win-loss">
    <div v-for="map in pickedMaps" :key="map.map_id" class="score">
      <img
        v-if="!scoresToShow.find((score) => score.map_id == map.map_id)"
        class="loss"
        src="../assets/LOSS.svg"
      />
      <img
        v-else-if="
          scoresToShow.find((score) => score.map_id == map.map_id).team === 'p1'
        "
        class="score"
        :src="getCountryImage($store.state.tournament.currentMatch.p1.country)"
      />
      <img
        v-else-if="
          scoresToShow.find((score) => score.map_id == map.map_id).team === 'p2'
        "
        class="score"
        :src="getCountryImage($store.state.tournament.currentMatch.p2.country)"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags/", false, /\.png$/);
      return images("./" + country.toUpperCase() + ".png");
    },
  },
  computed: {
    pickedMaps() {
      let scores = this.scoresToShow;
      let pickedMaps = this.$store.state.tournament.currentMapPool.maps.filter(
        (map) => {
          return !this.$store.state.tournament.currentMatch.bans.find(
            (ban) => ban.map_key === map.map_key
          );
        }
      );
      let finalMaps = [
        ...scores.map((score) => {
          return pickedMaps.find((map) => map.map_id == score.map_id);
        }),
        ...pickedMaps.filter((map) => {
          return !scores.find((score) => score.map_id == map.map_id);
        }),
      ].filter((map) => map);

      return finalMaps.slice(
        0,
        this.$store.state.tournament.currentMatch.best_of
      );
    },
    scoresToShow() {
      return this.$store.state.tournament.currentMatch.scores.map((score) => {
        if (score.p1_score > score.p2_score) {
          return {
            team: "p1",
            map_id: score.map_id,
          };
        } else {
          return {
            team: "p2",
            map_id: score.map_id,
          };
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.round-win-loss {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;

  img {
    width: 32px;
  }
}
</style>
