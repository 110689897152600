<template>
  <div
    class="bracket-slot"
    :class="`${match.status == 'in_progress' ? 'in_progress' : ''}`"
  >
    <div class="p1">
      <div v-if="match.p1.name !== null" class="info">
        <img :src="getCountryImage(match.p1.name)" />
        <span>{{ match.p1.name }}</span>
      </div>
      <div
        v-if="match.p1.name !== null"
        class="score"
        :class="`${
          match.status == 'complete'
            ? match.p1.score > match.p2.score
              ? 'winning'
              : 'losing'
            : ''
        }`"
      >
        {{ match.p1.score }}
      </div>
    </div>
    <div class="p2">
      <div v-if="match.p2.name !== null" class="info">
        <img :src="getCountryImage(match.p2.name)" />
        <span>{{ match.p2.name }}</span>
      </div>
      <div
        v-if="match.p2.name !== null"
        class="score"
        :class="`${
          match.status == 'complete'
            ? match.p2.score > match.p1.score
              ? 'winning'
              : 'losing'
            : ''
        }`"
      >
        {{ match.p2.score }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["match"],
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags_new/", false, /\.png$/);
      return images("./" + this.getCountryString(country) + ".png");
    },
    getCountryString(country) {
      return country.toLowerCase().replace(" ", "-");
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes in-progress-glow {
  0% {
    background: #ffffff;
  }
  50% {
    background: #ffffc3;
  }
  100% {
    background: #ffffff;
  }
}

.bracket-slot {
  background: #fff;
  border-radius: 11px;
  width: 260px;
  height: 67px;

  &.in_progress {
    animation: in-progress-glow 1500ms infinite;
  }

  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 32px;

    .info {
      padding: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;

      img {
        margin-top: calc(7.5px / 2 * -1);
        margin-bottom: calc(7.5px / 2 * -1);
        width: 24px;
      }

      span {
        color: black;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .score {
      color: black;
      margin-right: 9px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: right;

      &.winning {
        font-weight: 700;
      }
    }
  }

  & .p1 {
    border-bottom: 1px solid #ececec;
  }
}
</style>
