import Vue from "vue";
import App from "./App.vue";

import VueSocketIO from "vue-socket.io";
import VueRouter from "vue-router";

import store from "./store";
import ImageFallBack from "./fallback-directive";

import UpNextVue from "./components/UpNext.vue";
import PrizePoolVue from "./components/PrizePool.vue";
import PreviousMatchesVue from "./components/PreviousMatches.vue";
import PreviousMatchesRoundVue from "./components/PreviousMatchesRound.vue";
import PlayersVue from "./components/Players.vue";
import TeamsHeaderVue from "./components/TeamsHeader.vue";
import PicksAndBansVue from "./components/PicksAndBans.vue";
import CurrentMapVue from "./components/CurrentMap.vue";
import TeamFlagsVue from "./components/TeamFlags.vue";
import TeamPlayersVue from "./components/TeamPlayers.vue";
import TugOfWarVue from "./components/TugOfWar.vue";
import UpNextHorizontalVue from "./components/UpNextHorizontal.vue";
import BracketVue from "./components/Bracket.vue";
import TeamsHeaderSmallVue from "./components/TeamsHeaderSmall.vue";
import RoundWinLossVue from "./components/RoundWinLoss.vue";
import LastPlayedVue from "./components/LastPlayed.vue";
import MatchResultVue from "./components/MatchResult.vue";
import MatchResultBackgroundVue from "./components/MatchResultBackground.vue";
import CasterViewVue from "./components/CasterView.vue";
import PodiumSceneVue from "./components/PodiumScene.vue";

Vue.config.productionTip = false;

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: process.env.VUE_APP_HOST
      ? process.env.VUE_APP_HOST
      : "http://localhost:3410",
  })
);

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/current-map",
      name: "CurrentMap",
      component: CurrentMapVue,
    },
    {
      path: "/up-next",
      name: "UpNext",
      component: UpNextVue,
    },
    {
      path: "/previous-matches",
      name: "PreviousMatches",
      component: PreviousMatchesVue,
    },
    {
      path: "/previous-matches/round",
      name: "PreviousMatchesRound",
      component: PreviousMatchesRoundVue,
    },
    {
      path: "/prize-pool",
      name: "PrizePool",
      component: PrizePoolVue,
    },
    {
      path: "/players",
      name: "Players",
      component: PlayersVue,
    },
    {
      path: "/teams-header",
      name: "TeamsHeader",
      component: TeamsHeaderVue,
    },
    {
      path: "/teams-header/show-round",
      name: "TeamsHeader",
      component: TeamsHeaderVue,
      props: { showRound: true },
    },
    {
      path: "/picks-and-bans",
      name: "PicksAndBans",
      component: PicksAndBansVue,
    },
    {
      path: "/team-flags",
      name: "TeamFlags",
      component: TeamFlagsVue,
    },
    {
      path: "/team-players",
      name: "TeamPlayers",
      component: TeamPlayersVue,
    },
    {
      path: "/tug-of-war",
      name: "TugOfWar",
      component: TugOfWarVue,
    },
    {
      path: "/up-next/horizontal",
      name: "UpNextHorizontal",
      component: UpNextHorizontalVue,
    },
    {
      path: "/bracket",
      name: "Bracket",
      component: BracketVue,
    },
    {
      path: "/teams-header/small",
      name: "TeamsHeaderSmall",
      component: TeamsHeaderSmallVue,
    },
    {
      path: "/round-win-loss",
      name: "RoundWinLoss",
      component: RoundWinLossVue,
    },
    {
      path: "/last-played",
      name: "LastPlayed",
      component: LastPlayedVue,
    },
    {
      path: "/match-result",
      name: "MatchResult",
      component: MatchResultVue,
    },
    {
      path: "/match-result/background",
      name: "MatchResultBackground",
      component: MatchResultBackgroundVue,
    },
    {
      path: "/caster-view",
      name: "CasterView",
      component: CasterViewVue,
    },
    {
      path: "/podium/:team",
      name: "Podium",
      component: PodiumSceneVue,
    },
  ],
  mode: "history",
});

Vue.directive("image-fall-back", ImageFallBack);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
