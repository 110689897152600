var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.tournament.currentMatch)?_c('div',[_c('section',{staticClass:"section",staticStyle:{"padding":"0 0","display":"flex"}},[_c('div',{staticStyle:{"width":"50%","display":"flex"}},[_c('div',{style:([
          { width: 100 - _vm.p1Percent + '%' },
          { transition: 'width 1s' } ])}),_c('div',{staticClass:"tug",style:([{ 'background-color': '#fff' }, { width: _vm.p1Percent + '%' }])})]),_c('div',{staticStyle:{"width":"50%"}},[_c('div',{style:([
          { width: 100 - _vm.p2Percent + '%' },
          { transition: 'width 1s' } ])}),_c('div',{staticClass:"tug",style:([{ 'background-color': '#fff' }, { width: _vm.p2Percent + '%' }])})])]),_c('section',{staticClass:"section",staticStyle:{"padding":"0 0","display":"flex"}},[_c('div',{staticStyle:{"width":"50%","display":"flex"}},[_c('span',{class:[
          { 'big-acc': _vm.p1Acc > _vm.p2Acc },
          { 'small-acc': _vm.p1Acc < _vm.p2Acc },
          { 'small-acc': _vm.p1Acc === _vm.p2Acc } ],staticStyle:{"transition":"font-size 1s, top 1s","position":"absolute","z-index":"10000000","color":"#fff","width":"50%","font-weight":"800","text-align":"right","top":"8px","padding-right":"5px"}},[_vm._v(_vm._s((_vm.p1Acc ? Math.round(_vm.p1Acc * 100) / 100 : 0).toFixed(2))+"%")]),_c('span',{class:[
          { 'big-score': _vm.p1Acc > _vm.p2Acc },
          { 'small-score': _vm.p1Acc < _vm.p2Acc },
          { 'small-score': _vm.p1Acc === _vm.p2Acc } ],staticStyle:{"transition":"font-size 1s, top 1s","position":"absolute","z-index":"10000000","color":"#ffff00","width":"50%","text-align":"right","padding-right":"5px"}},[_vm._v(_vm._s(_vm.p1Score.toLocaleString()))])]),_c('div',{staticStyle:{"width":"50%"}},[_c('span',{class:[
          { 'big-acc': _vm.p1Acc < _vm.p2Acc },
          { 'small-acc': _vm.p1Acc > _vm.p2Acc },
          { 'small-acc': _vm.p1Acc === _vm.p2Acc } ],staticStyle:{"transition":"font-size 1s, top 1s","position":"absolute","z-index":"10000000","color":"#fff","width":"50%","font-weight":"800","text-align":"left","top":"8px","left":"50.3%","padding-left":"5px"}},[_vm._v(_vm._s((_vm.p2Acc ? Math.round(_vm.p2Acc * 100) / 100 : 0).toFixed(2))+"%")]),_c('span',{class:[
          { 'big-score': _vm.p1Acc < _vm.p2Acc },
          { 'small-score': _vm.p1Acc > _vm.p2Acc },
          { 'small-score': _vm.p1Acc === _vm.p2Acc } ],staticStyle:{"transition":"font-size 1s, top 1s","position":"absolute","z-index":"10000000","color":"#ffff00","width":"50%","text-align":"left","left":"50.3%","padding-left":"5px"}},[_vm._v(_vm._s(_vm.p2Score.toLocaleString()))])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }