<template>
  <div v-if="$store.state.tournament.currentMatch">
    <section class="section" style="padding: 0 0; display: flex">
      <div style="width: 50%; display: flex">
        <div
          :style="[
            { width: 100 - p1Percent + '%' },
            { transition: 'width 1s' },
          ]"
        ></div>
        <div
          class="tug"
          :style="[{ 'background-color': '#fff' }, { width: p1Percent + '%' }]"
        ></div>
      </div>
      <div style="width: 50%">
        <div
          :style="[
            { width: 100 - p2Percent + '%' },
            { transition: 'width 1s' },
          ]"
        ></div>
        <div
          class="tug"
          :style="[{ 'background-color': '#fff' }, { width: p2Percent + '%' }]"
        ></div>
      </div>
    </section>
    <section class="section" style="padding: 0 0; display: flex">
      <div style="width: 50%; display: flex">
        <span
          :class="[
            { 'big-acc': p1Acc > p2Acc },
            { 'small-acc': p1Acc < p2Acc },
            { 'small-acc': p1Acc === p2Acc },
          ]"
          style="
            transition: font-size 1s, top 1s;
            position: absolute;
            z-index: 10000000;
            color: #fff;
            width: 50%;
            font-weight: 800;
            text-align: right;
            top: 8px;
            padding-right: 5px;
          "
          >{{ (p1Acc ? Math.round(p1Acc * 100) / 100 : 0).toFixed(2) }}%</span
        >
        <span
          :class="[
            { 'big-score': p1Acc > p2Acc },
            { 'small-score': p1Acc < p2Acc },
            { 'small-score': p1Acc === p2Acc },
          ]"
          style="
            transition: font-size 1s, top 1s;
            position: absolute;
            z-index: 10000000;
            color: #ffff00;
            width: 50%;
            text-align: right;
            padding-right: 5px;
          "
          >{{ p1Score.toLocaleString() }}</span
        >
      </div>
      <div style="width: 50%">
        <span
          :class="[
            { 'big-acc': p1Acc < p2Acc },
            { 'small-acc': p1Acc > p2Acc },
            { 'small-acc': p1Acc === p2Acc },
          ]"
          style="
            transition: font-size 1s, top 1s;
            position: absolute;
            z-index: 10000000;
            color: #fff;
            width: 50%;
            font-weight: 800;
            text-align: left;
            top: 8px;
            left: 50.3%;
            padding-left: 5px;
          "
          >{{ (p2Acc ? Math.round(p2Acc * 100) / 100 : 0).toFixed(2) }}%</span
        >
        <span
          :class="[
            { 'big-score': p1Acc < p2Acc },
            { 'small-score': p1Acc > p2Acc },
            { 'small-score': p1Acc === p2Acc },
          ]"
          style="
            transition: font-size 1s, top 1s;
            position: absolute;
            z-index: 10000000;
            color: #ffff00;
            width: 50%;
            text-align: left;
            left: 50.3%;
            padding-left: 5px;
          "
          >{{ p2Score.toLocaleString() }}</span
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TugOfWar",
  data() {
    return {
      taMatch: null,
      p1Percent: 0,
      p2Percent: 0,
      p1Score: 0,
      p1Acc: 0,
      p2Score: 0,
      p2Acc: 0,

      players: [],
    };
  },
  sockets: {
    resetOverlay() {
      this.p1Percent = 0;
      this.p2Percent = 0;
      this.p1Score = 0;
      this.p1Acc = 0;
      this.p2Score = 0;
      this.p2Acc = 0;
    },
    data(res) {
      if (res.type === "taMatch") {
        this.taMatch = res.data;
        if (!this.taMatch) return;
        if (!this.$store.state.tournament.currentMatch) return;

        let p1Score = 0;
        let p2Score = 0;

        let p1Acc = 0;
        let p2Acc = 0;

        for (let player of this.taMatch.associated_users) {
          if (
            this.$store.state.tournament.currentMatch.p1.players.find(
              (pl) => pl.scoresaber == player.user_id
            )
          ) {
            p1Score += player.score;
            p1Acc += player.accuracy * 100;
          } else if (
            this.$store.state.tournament.currentMatch.p2.players.find(
              (pl) => pl.scoresaber == player.user_id
            )
          ) {
            p2Score += player.score;
            p2Acc += player.accuracy * 100;
          }
        }

        if (p1Score !== 0) {
          this.p1Score = p1Score;
        }

        if (p2Score !== 0) {
          this.p2Score = p2Score;
        }

        if (p1Acc !== 0) {
          this.p1Acc = p1Acc / 4;
        }

        if (p2Acc !== 0) {
          this.p2Acc = p2Acc / 4;
        }
      }
    },
  },
  watch: {
    async p1Acc() {
      if (this.p1Acc > this.p2Acc) {
        this.p1Percent =
          (this.p1Acc - this.p2Acc) * 100 > 0
            ? (this.p1Acc - this.p2Acc) * 100
            : (this.p1Acc - this.p2Acc) * 100 * -1;
        if (this.p1Percent > 100) {
          this.p1Percent = 100;
        }
        this.p2Percent = 0;
      }
    },
    async p2Acc() {
      if (this.p1Acc < this.p2Acc) {
        this.p2Percent =
          (this.p2Acc - this.p1Acc) * 100 > 0
            ? (this.p2Acc - this.p1Acc) * 100
            : (this.p2Acc - this.p1Acc) * 100 * -1;
        if (this.p2Percent > 100) {
          this.p2Percent = 100;
        }
        this.p1Percent = 0;
      }
    },
  },
};
</script>

<style scoped>
.tug {
  padding-top: 7px;
  transition: width 1s;
}
.big-score {
  top: 67px !important;
  font-size: 22px !important;
}
.big-acc {
  font-size: 50px !important;
}
.small-score {
  top: 47px !important;
  font-size: 18px !important;
}
.small-acc {
  font-size: 30px !important;
}
</style>
