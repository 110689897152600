<template>
  <svg
    width="2411"
    height="358"
    viewBox="0 0 2411 358"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="[{ 'margin-left': roundMargin }]"
  >
    <path
      d="M689 34H763.898C770 34 781.994 37.2649 781.994 56.261C781.994 75.257 781.994 104.74 781.994 117.108C782.162 121.725 778.777 130.959 763.898 130.959C749.019 130.959 707.766 130.959 689 130.959"
      stroke="white"
    />
    <path
      d="M689 228H763.898C770 228 781.994 231.265 781.994 250.261C781.994 269.257 781.994 298.74 781.994 311.108C782.162 315.725 778.777 324.959 763.898 324.959C749.019 324.959 707.766 324.959 689 324.959"
      stroke="white"
    />
    <path d="M782.5 81.4587H858.5" stroke="#E7E7E7" />
    <path d="M782.5 275.459H858.5" stroke="#E7E7E7" />
    <path d="M260 34H429" stroke="#E7E7E7" />
    <path d="M1118 81.5H1290" stroke="#E7E7E7" />
    <path d="M1979 176.5H2151" stroke="#E7E7E7" />
    <path d="M1118 275.5H1290" stroke="#E7E7E7" />
    <path d="M260 228H429" stroke="#E7E7E7" />
    <path d="M260 131H429" stroke="#E7E7E7" />
    <path d="M260 325H429" stroke="#E7E7E7" />
    <path
      d="M1550 81.5L1602.5 81.5C1635 81.5 1642.99 89.0389 1642.99 123.215C1642.99 157.391 1642.99 223.041 1642.99 247.786C1642.99 262.433 1631.5 275.5 1608.5 275.5C1593.62 275.5 1568.77 275.5 1550 275.5"
      stroke="white"
    />
    <path d="M1643.5 176.458H1719.5" stroke="#E7E7E7" />
    <foreignObject x="858" y="241.5" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -3)[1]" />
    </foreignObject>

    <foreignObject x="1290" y="241.5" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -4)[1]" />
    </foreignObject>

    <foreignObject x="858" y="47.5" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -3)[0]" />
    </foreignObject>
    <!-- <rect x="858" y="47.5" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject x="1290" y="47.5" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -4)[0]" />
    </foreignObject>
    <!-- <rect x="1290" y="47.5" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject x="1719" y="142.5" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -5)[0]" />
    </foreignObject>
    <!-- <rect x="1719" y="142.5" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject x="2151" y="142.5" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -6)[0]" />
    </foreignObject>
    <!-- <rect x="2151" y="142.5" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -1)[0]" />
    </foreignObject>
    <!-- <rect width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject y="194" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -1)[2]" />
    </foreignObject>
    <!-- <rect y="194" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject y="97" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -1)[1]" />
    </foreignObject>
    <!-- <rect y="97" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject y="291" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -1)[3]" />
    </foreignObject>
    <!-- <rect y="291" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject x="429" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -2)[0]" />
    </foreignObject>
    <!-- <rect x="429" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject x="429" y="194" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -2)[2]" />
    </foreignObject>
    <!-- <rect x="429" y="194" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject x="429" y="97" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -2)[1]" />
    </foreignObject>
    <!-- <rect x="429" y="97" width="260" height="67" rx="11" fill="white" /> -->

    <foreignObject x="429" y="291" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === -2)[3]" />
    </foreignObject>
    <!-- <rect x="429" y="291" width="260" height="67" rx="11" fill="white" /> -->
  </svg>
</template>

<script>
import BracketSlot from "./BracketSlot.vue";
export default {
  components: { BracketSlot },
  props: ["bracket"],
  computed: {
    roundMargin() {
      let currentMatch = this.$store.state.tournament.currentMatch;
      if (currentMatch) {
        if (currentMatch.round === -3) {
          return "-429px";
        } else if (currentMatch.round === -4) {
          return "-857px";
        } else if (currentMatch.round <= -5) {
          return "-1290px";
        }
      }
      return "0px";
    },
  },
};
</script>

<style scoped>
svg {
  transition: margin-left 1.5s ease-in-out;
}
</style>
