export default {
  bind(el, binding) {
    try {
      const { value } = binding;
      const fallBackImage = "https://cdn.scoresaber.com/avatars/oculus.png";
      const img = new Image();
      let error = fallBackImage;
      let original = el.src;
      if (typeof value === "string") {
        error = value;
      }
      if (value instanceof Object) {
        error = value.fallBackImage || fallBackImage;
      }
      img.src = original;
      img.onload = () => {
        el.src = original;
      };
      img.onerror = () => {
        el.src = error;
      };
    } catch (e) {
      console.log(e);
    }
  },
};
