<template>
  <div
    v-if="
      this.$store.state.tournament.currentMapPool &&
      this.$store.state.tournament.currentMatch.scores[
        this.$store.state.tournament.currentMatch.scores.length - 1
      ] &&
      lastMap
    "
    class="last-played"
  >
    <img :src="lastMap.cover_url" />
    <div class="name">
      <span>{{ lastMap.map_name }}</span>
    </div>
    <div class="details" :class="colorClass">
      <div class="diff">{{ lastMap.display_diff }}</div>
      <marquee class="info"
        >Key: {{ lastMap.map_key }} - Mapped by {{ lastMap.map_mapper }} - Taken
        by {{ this.$store.state.tournament.currentMatch[mapWonBy].name }}
      </marquee>
    </div>
  </div>
  <div v-else class="not-yet"><span>No map played yet</span></div>
</template>

<script>
export default {
  computed: {
    lastMap() {
      return this.$store.state.tournament.currentMapPool.maps.find(
        (map) =>
          map.map_id ==
          this.$store.state.tournament.currentMatch.scores[
            this.$store.state.tournament.currentMatch.scores.length - 1
          ].map_id
      );
    },
    colorClass() {
      return `bg-${this.lastMap.map_diff}`;
    },
    mapWonBy() {
      let score =
        this.$store.state.tournament.currentMatch.scores[
          this.$store.state.tournament.currentMatch.scores.length - 1
        ];
      return score ? (score.p1_score > score.p2_score ? "p1" : "p2") : "";
    },
  },
};
</script>

<style scoped lang="scss">
.not-yet {
  --height: 38px;

  display: flex;
  align-items: center;
  height: 100vh;

  span {
    display: flex;
    align-items: center;

    background-color: white;
    border-radius: 10px;

    height: var(--height);
    width: fit-content;

    font-size: 16px;

    font-style: normal;
    font-weight: 600;

    padding: 0 12px;
  }
}

.last-played {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;

  --height: 38px;

  gap: 10px;

  img {
    width: var(--height);
    height: var(--height);

    background: #969696;
    border-radius: 10px;
  }

  .name {
    display: flex;
    align-items: center;

    background-color: white;
    border-radius: 10px;

    height: var(--height);
    font-size: 16px;

    font-style: normal;
    font-weight: 600;

    padding: 0 12px;

    span {
      width: fit-content;
      max-width: 220px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .details {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 12px;

    height: var(--height);
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;

    color: white;

    --bg-easy: #14ff00;
    --bg-normal: #00b2ff;
    --bg-hard: #ffa800;
    --bg-expert: #ff0000;
    --bg-expertPlus: #eb00ff;

    .diff {
      padding: 0 12px;
      height: 100%;
      display: flex;
      align-items: center;

      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      color: black;

      font-style: normal;
      font-weight: 600;
    }

    .info {
      max-width: 200px;
    }

    &.bg-easy {
      border-color: var(--bg-easy);
      .diff {
        background-color: var(--bg-easy);
      }
    }

    &.bg-normal {
      border-color: var(--bg-normal);
      .diff {
        background-color: var(--bg-normal);
      }
    }

    &.bg-hard {
      border-color: var(--bg-hard);
      .diff {
        background-color: var(--bg-hard);
      }
    }

    &.bg-expert {
      border-color: var(--bg-expert);
      .diff {
        background-color: var(--bg-expert);
      }
    }

    &.bg-expertPlus {
      border-color: var(--bg-expertPlus);
      .diff {
        background-color: var(--bg-expertPlus);
      }
    }
  }
}
</style>
