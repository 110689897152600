<template>
  <div class="flags">
    <img
      :src="getCountryImage($store.state.tournament.currentMatch.p1.country)"
      class="p1"
    />
    <img
      :src="getCountryImage($store.state.tournament.currentMatch.p2.country)"
      class="p2"
    />
  </div>
</template>

<script>
export default {
  name: "TeamFlags",
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags/", false, /\.png$/);
      return images("./" + country.toUpperCase() + ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
.flags {
  opacity: 0.4;

  .p1 {
    position: absolute;
    width: 1800px;
    top: -550px;
    left: -590px;
    transform: rotate(15deg);
  }

  .p2 {
    position: absolute;
    width: 2000px;
    top: -325px;
    right: -918px;
    transform: rotate(15deg);
  }
}
</style>
