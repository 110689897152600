var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"embeds"},[_c('div',{staticClass:"details"},_vm._l((_vm.overlayPlayers),function(player){return _c('div',{key:player.participantID,staticClass:"playerDetails",class:{
        p1: player.team === 'p1',
        p2: player.team === 'p2',
        missed: _vm.missesPerPlayer[player.scoresaber]
          ? _vm.missesPerPlayer[player.scoresaber].showAnimation
          : false,
        mvp: _vm.mvp === player.scoresaber,
      }},[_c('div',{staticClass:"profile"},[_c('div',{staticClass:"avatar"},[_c('img',{directives:[{name:"image-fall-back",rawName:"v-image-fall-back"}],attrs:{"src":("https://new.scoresaber.com" + (player.pictureUrl))}}),_c('svg',{staticClass:"hexagon",attrs:{"width":"424","height":"464","viewBox":"0 0 424 464","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M51.52 98.1301L176.43 26.0103C187.223 19.7787 199.467 16.498 211.93 16.498C224.393 16.498 236.637 19.7787 247.43 26.0103L372.34 98.1301C383.152 104.358 392.132 113.326 398.375 124.13C404.618 134.934 407.903 147.192 407.9 159.67V303.9C407.904 316.371 404.623 328.622 398.387 339.422C392.152 350.222 383.182 359.188 372.38 365.42L247.47 437.54C236.677 443.772 224.433 447.052 211.97 447.052C199.507 447.052 187.263 443.772 176.47 437.54L51.52 365.4C40.7182 359.168 31.7483 350.202 25.5128 339.402C19.2774 328.602 15.9964 316.351 16 303.88V159.67C15.9929 147.196 19.2722 134.941 25.5078 124.137C31.7434 113.334 40.7153 104.364 51.52 98.1301V98.1301Z","stroke":"white","stroke-width":"32","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(player.name))])]),(
          _vm.taMatch &&
          _vm.taMatch.associated_users.find(
            function (user) { return user.user_id == player.scoresaber; }
          )
        )?_c('div',{staticClass:"score"},[_c('transition',{attrs:{"name":"combo-transition","mode":"out-in"}},[_c('div',{key:_vm.taMatch.associated_users.find(
                function (user) { return user.user_id == player.scoresaber; }
              ).combo,staticClass:"combo"},[_vm._v(" "+_vm._s(_vm.taMatch.associated_users.find( function (user) { return user.user_id == player.scoresaber; } ).combo + "x ")+" "),(
                _vm.taMatch.associated_users.find(
                  function (user) { return user.user_id == player.scoresaber; }
                ).misses === 0
              )?_c('span',{staticClass:"fc"},[_vm._v(" FC ")]):(
                _vm.taMatch.associated_users.find(
                  function (user) { return user.user_id == player.scoresaber; }
                ).misses > 0
              )?_c('span',{staticClass:"fc miss"},[_vm._v(" "+_vm._s(_vm.taMatch.associated_users.find( function (user) { return user.user_id == player.scoresaber; } ).misses)+"x ")]):_vm._e()])]),_c('div',{staticClass:"accuracy"},[_vm._v(" "+_vm._s(Math.round( _vm.taMatch.associated_users.find( function (user) { return user.user_id == player.scoresaber; } ).accuracy * 10000 ) / 100)+"% ")])],1):_vm._e(),_c('div',{staticClass:"mvp-label"},[_vm._v("MVP")])])}),0),_c('div',{staticClass:"players"},_vm._l((_vm.overlayPlayers),function(player){return _c('div',{key:player.participantID,staticClass:"player"},[_c('div',{staticClass:"wrapper"},[(_vm.unmutedPlayer === player.streamUrl)?_c('img',{class:("sound-" + (player.team)),attrs:{"src":require("../assets/sound.svg")}}):_vm._e(),_c('iframe',{attrs:{"frameborder":"0","width":"100%","height":"100%","src":'https://player.twitch.tv/?enableExtensions=false&muted=' +
            (_vm.unmutedPlayer !== player.streamUrl) +
            '&!scrolling&channel=' +
            player.streamUrl.replace('https://www.twitch.tv/', '') +
            '&height=1080&parent=twitch.tv&parent=overlay.beatsaberworldcup.com&parent=localhost&player=popout&volume=1&width=1920&refresh=' +
            _vm.refreshPlayers.includes(player.participantID),"allowfullscreen":"","allow":"autoplay"}})])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }