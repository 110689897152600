<template>
  <div v-if="$store.state.tournament.currentMatch" class="teams">
    <div v-for="team of ['p1', 'p2']" :key="team" :class="team">
      <div class="flag">
        <img
          :src="
            getCountryImage($store.state.tournament.currentMatch[team].country)
          "
          alt="flag"
        />
      </div>
      <div class="info">
        <div class="seedAndScore">
          <div class="seed">
            Seed {{ $store.state.tournament.currentMatch[team].seed }}
          </div>
        </div>
        <div class="details">
          <span class="name">
            {{ $store.state.tournament.currentMatch[team].name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamsHeader",
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags/", false, /\.png$/);
      return images("./" + country.toUpperCase() + ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
.teams {
  padding: 21px 26px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .p2 {
    flex-direction: row-reverse;

    .info .seedAndScore {
      flex-direction: row-reverse;

      .score {
        flex-direction: row-reverse;
      }
    }

    .info .details {
      justify-content: flex-end;
    }
  }

  .p1,
  .p2 {
    display: flex;
    gap: 10px;

    .flag {
      display: flex;
      align-items: center;

      img {
        width: 80px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .seedAndScore {
        display: flex;
        gap: 10px;

        .seed {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000;

          background: white;
          border-radius: 13px;
          padding: 6px 10px;

          width: fit-content;
        }
      }

      .details {
        display: flex;

        .name {
          font-style: normal;
          font-weight: 800;
          font-size: 28px;
          color: #000;

          background: white;
          border-radius: 17px;
          padding: 7px 13px;

          width: fit-content;
        }
      }
    }
  }
}
</style>
