<template>
  <div class="caster-view">
    <iframe
      :src="`${addr}/players`"
      class="players"
      height="714"
      width="1920"
    ></iframe>
    <iframe
      :src="`${addr}/tug-of-war`"
      class="tug"
      height="714"
      width="1920"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    let addr = process.env.VUE_APP_HOST;
    return {
      addr: addr
        ? "https://overlay.beatsaberworldcup.com"
        : "http://localhost:8080",
    };
  },
};
</script>

<style scoped>
iframe {
  border: 0;
  width: 100%;
  height: 714px;
}
</style>
