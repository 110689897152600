<template>
  <div v-if="$store.state.tournament.currentMatch" class="teams">
    <div v-if="showRound" class="round">
      <span>{{ $store.state.tournament.currentMatch.roundName }}</span>
    </div>
    <div v-for="team of ['p1', 'p2']" :key="team" :class="team">
      <div class="flag">
        <img
          :src="
            getCountryImage($store.state.tournament.currentMatch[team].country)
          "
          alt="flag"
        />
      </div>
      <div class="info">
        <div class="seedAndScore">
          <div class="seed">
            Seed {{ $store.state.tournament.currentMatch[team].seed }}
          </div>
          <div class="score">
            <img
              v-for="i in $store.state.tournament.currentMatch[team].score"
              :key="'score-' + i"
              class="win"
              src="../assets/WIN.svg"
            />
            <img
              v-for="i in Math.ceil(
                $store.state.tournament.currentMatch.best_of / 2
              ) - $store.state.tournament.currentMatch[team].score"
              :key="'noScore-' + i"
              class="loss"
              src="../assets/LOSS.svg"
            />
          </div>
        </div>
        <div class="details">
          <span class="name">
            {{ $store.state.tournament.currentMatch[team].name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamsHeader",
  props: {
    showRound: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags/", false, /\.png$/);
      return images("./" + country.toUpperCase() + ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
.teams {
  padding: 43px 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .round {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: right;
    position: absolute;
    top: 90px;
    right: 1100px;
  }

  .p2 {
    flex-direction: row-reverse;

    .info .seedAndScore {
      flex-direction: row-reverse;

      .score {
        flex-direction: row-reverse;
      }
    }

    .info .details {
      justify-content: flex-end;
    }
  }

  .p1,
  .p2 {
    display: flex;
    gap: 10px;

    .flag {
      display: flex;
      align-items: center;

      img {
        width: 100px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .seedAndScore {
        display: flex;
        gap: 10px;

        .seed {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #000;

          background: white;
          border-radius: 13px;
          padding: 8px 14px;

          width: fit-content;
        }

        .score {
          display: flex;
          gap: 5px;
          align-items: center;

          img {
            width: 32px;
          }
        }
      }

      .details {
        display: flex;

        .name {
          font-style: normal;
          font-weight: 800;
          font-size: 32px;
          color: #000;

          background: white;
          border-radius: 20px;
          padding: 10px 15px;

          width: fit-content;
        }
      }
    }
  }
}
</style>
