<template>
  <div v-if="computedMatches" class="previous-matches">
    <div class="bracket-shown">
      {{ $store.state.tournament.currentMatch.roundName }}
    </div>
    <div class="matches">
      <div
        v-for="(match, index) in computedMatches"
        :key="match.id"
        :data-index="index"
        class="match"
      >
        <div class="bracket-slot">
          <div class="p1" :class="{ lost: match.p2.score > match.p1.score }">
            <div class="info">
              <img :src="getCountryImage(match.p1.name)" />
              <span>{{ match.p1.name }}</span>
            </div>
            <div class="score">{{ match.p1.score }}</div>
          </div>
          <div class="p2" :class="{ lost: match.p1.score > match.p2.score }">
            <div class="info">
              <img :src="getCountryImage(match.p2.name)" />
              <span>{{ match.p2.name }}</span>
            </div>
            <div class="score">{{ match.p2.score }}</div>
          </div>
        </div>

        <span class="tag green" :class="{ p2: match.p2.score > match.p1.score }"
          >Winner</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags_new/", false, /\.png$/);
      return images("./" + this.getCountryString(country) + ".png");
    },
    getCountryString(country) {
      return country.toLowerCase().replace(" ", "-");
    },
  },
  computed: {
    computedMatches() {
      return this.$store.state.tournament.bracket &&
        this.$store.state.tournament.currentMatch
        ? this.$store.state.tournament.bracket.bracket
            .filter(
              (match) =>
                match.p1.country !== null &&
                match.p2.country !== null &&
                match.time !== null &&
                match.status === "complete" &&
                this.$store.state.tournament.currentMatch.roundName ===
                  match.roundName
            )
            .sort((a, b) => new Date(b.time) - new Date(a.time))
            .slice(0, 7)
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.bracket-shown {
  position: absolute;
  left: 160px;
  top: 0px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #000000;

  background-color: #fff;
  border-radius: 12px;
  padding: 8px 12px;

  width: fit-content;

  text-align: right;
  margin-bottom: 25px;
}

.matches {
  margin-top: 70px;
}

.match {
  display: flex;
  flex-direction: row;
  gap: 18px;
  margin-bottom: 30px;
  overflow: hidden;
}

.tag {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  padding: 6px 9px;
  width: fit-content;

  border-radius: 8px;

  height: 18px;

  display: flex;
  align-items: center;

  &.blue {
    background: #56c2ff;
    color: #011a38;
  }

  &.red {
    background: #ffd2ea;
    color: #8e1c5a;
  }

  &.green {
    background: #14520e;
    color: #00ff38;

    &.p2 {
      margin-top: 34px;
    }
  }
}

.bracket-slot {
  color: white;

  background: #000000;
  border-radius: 11px;

  width: 260px;

  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      padding: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;

      img {
        margin-top: calc(7.5px / 2 * -1);
        margin-bottom: calc(7.5px / 2 * -1);
        width: 24px;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .score {
      margin-right: 9px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
    }
  }

  & .p1 {
    border-bottom: 1px solid #383838;
  }

  & .lost {
    .info,
    .score {
      color: rgba(255, 255, 255, 0.25);
    }
  }
}
</style>
