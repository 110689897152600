var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bracket-slot",class:("" + (_vm.match.status == 'in_progress' ? 'in_progress' : ''))},[_c('div',{staticClass:"p1"},[(_vm.match.p1.name !== null)?_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":_vm.getCountryImage(_vm.match.p1.name)}}),_c('span',[_vm._v(_vm._s(_vm.match.p1.name))])]):_vm._e(),(_vm.match.p1.name !== null)?_c('div',{staticClass:"score",class:("" + (_vm.match.status == 'complete'
          ? _vm.match.p1.score > _vm.match.p2.score
            ? 'winning'
            : 'losing'
          : ''))},[_vm._v(" "+_vm._s(_vm.match.p1.score)+" ")]):_vm._e()]),_c('div',{staticClass:"p2"},[(_vm.match.p2.name !== null)?_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":_vm.getCountryImage(_vm.match.p2.name)}}),_c('span',[_vm._v(_vm._s(_vm.match.p2.name))])]):_vm._e(),(_vm.match.p2.name !== null)?_c('div',{staticClass:"score",class:("" + (_vm.match.status == 'complete'
          ? _vm.match.p2.score > _vm.match.p1.score
            ? 'winning'
            : 'losing'
          : ''))},[_vm._v(" "+_vm._s(_vm.match.p2.score)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }