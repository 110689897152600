<template>
  <div v-if="computedMatches" class="up-next">
    <div
      v-for="(match, index) in computedMatches"
      :key="match.id"
      class="match"
    >
      <span v-if="index === 1" class="tag white">Upcoming Matches</span>
      <span v-if="index !== 0" class="tag red">{{
        getMomentDate(match.time)
      }}</span>
      <div class="bracket-slot" :class="{ first: index === 0 }">
        <div class="p1">
          <div class="info">
            <img :src="getCountryImage(match.p1.name)" />
            <span>{{ match.p1.name }}</span>
          </div>
          <div class="score">{{ match.p1.score }}</div>
        </div>
        <div class="p2">
          <div class="info">
            <img :src="getCountryImage(match.p2.name)" />
            <span>{{ match.p2.name }}</span>
          </div>
          <div class="score">{{ match.p2.score }}</div>
        </div>
      </div>
      <span v-if="index === 0" class="tag blue">Up Next</span>
    </div>
  </div>
</template>

<script>
import Moment from "moment";

export default {
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags_new/", false, /\.png$/);
      return images("./" + this.getCountryString(country) + ".png");
    },
    getCountryString(country) {
      return country.toLowerCase().replace(" ", "-");
    },
    getMomentDate(date) {
      let dt = Moment(date).utc();
      return dt.format("HH:mm") + " UTC";
    },
  },
  computed: {
    computedMatches() {
      return this.$store.state.tournament.bracket
        ? this.$store.state.tournament.bracket.bracket
            .filter(
              (match) =>
                match.p1.country !== null &&
                match.p2.country !== null &&
                match.time !== null &&
                match.status === "awaiting_start" &&
                new Date(match.time).getDate() === new Date().getDate()
            )
            .sort((a, b) => new Date(a.time) - new Date(b.time))
            .slice(0, 3)
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.up-next {
  display: flex;
  flex-direction: row;
  gap: 42px;
}

.match {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  margin-bottom: 31px;
}

.tag {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  padding: 6px 9px;
  width: fit-content;
  height: fit-content;

  border-radius: 8px;

  &.blue {
    background: #56c2ff;
    color: #011a38;
  }

  &.red {
    background: #ffd2ea;
    color: #8e1c5a;
  }

  &.white {
    background: #fff;
    color: #000;
    margin-left: 41px;
  }
}

.bracket-slot {
  background: #000000;
  border-radius: 11px;
  min-width: 260px;

  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      padding: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;

      img {
        margin-top: calc(7.5px / 2 * -1);
        margin-bottom: calc(7.5px / 2 * -1);
        width: 24px;
      }

      span {
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .score {
      color: white;
      margin-right: 9px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
    }
  }

  & .p1 {
    border-bottom: 1px solid #383838;
  }
}
</style>
