<template>
  <div class="prize-pool">
    <span class="amount">{{ prizePoolAmount }}</span>
    <span class="usd">USD</span>
  </div>
</template>

<script>
export default {
  name: "PrizePool",
  computed: {
    prizePoolAmount() {
      return this.$store.state.tournament.prizePool
        ? this.$store.state.tournament.prizePool.amount.toFixed(2)
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.prize-pool {
  display: flex;
  gap: 75px;
  align-items: center;

  & .amount {
    font-style: normal;
    font-weight: 700;
    font-size: 165px;
    color: #fff;
  }

  & .usd {
    color: #00ff38;
    border: 13px solid #00ff38;
    padding: 10px 59px;
    border-radius: 64px;
    font-size: 165px;
  }
}
</style>
