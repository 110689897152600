<template>
  <div v-if="$store.state.tournament.currentMatch">
    <img :src="getCountryImage(winner.country)" class="flag" />
    <img :src="getCountryImage(winner.country)" class="flag-mid" />
    <span class="won-by"
      >{{ winner.name }} won {{ winner.score }}-{{ loser.score }}</span
    >
  </div>
</template>

<script>
export default {
  computed: {
    winner() {
      return this.$store.state.tournament.currentMatch.p1.score >
        this.$store.state.tournament.currentMatch.p2.score
        ? this.$store.state.tournament.currentMatch.p1
        : this.$store.state.tournament.currentMatch.p2;
    },
    loser() {
      return this.$store.state.tournament.currentMatch.p1.score >
        this.$store.state.tournament.currentMatch.p2.score
        ? this.$store.state.tournament.currentMatch.p2
        : this.$store.state.tournament.currentMatch.p1;
    },
  },
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags/", false, /\.png$/);
      return images("./" + country.toUpperCase() + ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
.won-by {
  position: absolute;
  left: 340px;
  top: 68px;
  font-size: 24px;
  color: white;
  z-index: 50;
}

.flag {
  height: 150vh;
  margin-left: -50vh;
  margin-top: -25vh;
  filter: blur(1px);
  opacity: 0.5;
}

.flag-mid {
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
