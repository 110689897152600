<template>
  <div v-if="$store.state.tournament.currentMatch">
    <div v-if="played" class="won-by">
      <img
        :src="
          getCountryImage(
            $store.state.tournament.currentMatch[mapWonBy].country
          )
        "
      />
    </div>
    <div
      :class="`map ${colorClass} ${played ? 'played' : ''} ${
        picked ? 'picked' : ''
      } ${banned ? 'banned' : ''} ${small ? 'small' : ''} ${
        tiebreaker ? 'tiebreaker' : ''
      }`"
    >
      <div class="cover">
        <img :src="map.cover_url" />
      </div>
      <div class="details">
        <div class="head">
          <div class="name">
            {{ map.map_name }}
          </div>
          <div class="key">
            {{ map.map_key }}
          </div>
        </div>
        <div class="info">
          <div class="text">
            Artist: {{ map.map_artist }}<br />
            Mapper: {{ map.map_mapper }}<br />
            Song Length: {{ songLength }}<br />
            BPM: {{ map.BeatSaver.metadata.bpm }}<br />
          </div>
          <div class="difficulty">
            <span :class="colorClass">{{ map.display_diff }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Map",
  props: {
    played: {
      type: Boolean,
      default: false,
    },
    picked: {
      type: Boolean,
      default: false,
    },
    banned: {
      type: Boolean,
      default: false,
    },
    tiebreaker: {
      type: Boolean,
      default: false,
    },
    map: {
      type: Object,
      default: () => ({
        cover_url: "",
        map_name: "",
        map_key: "",
        map_artist: "",
        map_mapper: "",
        BeatSaver: {
          metadata: {
            bpm: 0,
            duration: 0,
          },
        },
        display_diff: "",
      }),
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getCountryImage(country) {
      var images = require.context("../assets/flags/", false, /\.png$/);
      return images("./" + country.toUpperCase() + ".png");
    },
  },
  computed: {
    colorClass() {
      return `bg-${this.map.map_diff}`;
    },
    songLength() {
      return `${Math.floor(this.map.BeatSaver.metadata.duration / 60)}:${
        this.map.BeatSaver.metadata.duration % 60 < 10 ? "0" : ""
      }${this.map.BeatSaver.metadata.duration % 60}`;
    },
    mapWonBy() {
      let score = this.$store.state.tournament.currentMatch.scores.find(
        (score) => score.map_id == this.map.map_id
      );
      return score ? (score.p1_score > score.p2_score ? "p1" : "p2") : "";
    },
  },
};
</script>

<style scoped lang="scss">
.won-by {
  width: 466px;
  height: 120px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: absolute;
  z-index: 1000;

  img {
    width: 48px;
    margin-left: 52px;
  }
}

.map {
  --bg-easy: #14ff00;
  --bg-normal: #00b2ff;
  --bg-hard: #ffa800;
  --bg-expert: #ff0000;
  --bg-expertPlus: #eb00ff;

  --bg-semi-transparent-easy: rgba(20, 255, 0, 0.2);
  --bg-semi-transparent-normal: rgba(0, 178, 255, 0.2);
  --bg-semi-transparent-hard: rgba(255, 168, 0, 0.2);
  --bg-semi-transparent-expert: rgba(255, 0, 0, 0.2);
  --bg-semi-transparent-expertPlus: rgba(235, 0, 255, 0.2);

  position: relative;

  &.played {
    opacity: 0.7;
    filter: saturate(0.3);
  }

  &.picked::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 5px);
    background: rgba(51, 255, 0, 0.3);
    border-top: 3px solid #33ff00;
    border-right: 4px solid #33ff00;
    border-bottom: 3px solid #33ff00;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: opacity 2s;
  }

  &.banned::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 5px);
    background: rgba(255, 0, 0, 0.3);
    border-top: 2.5px solid #ff0000;
    border-right: 2px solid #ff0000;
    border-bottom: 2.5px solid #ff0000;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: opacity 2s;
  }

  &.tiebreaker::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 5px);
    background: rgba(255, 196, 0, 0.3);
    border-top: 3px solid #ff8800;
    border-right: 4px solid #ff8800;
    border-bottom: 3px solid #ff8800;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: opacity 2s;
  }

  &.small {
    width: 960px;

    .details .head .name {
      max-width: 405px;
    }
  }

  width: 430px;
  height: 125px;
  border-radius: 12px;
  border-left: 12px solid;

  display: flex;
  align-items: center;

  &.bg-easy {
    border-color: var(--bg-easy);
    background: var(--bg-semi-transparent-easy);
  }

  &.bg-normal {
    border-color: var(--bg-normal);
    background: var(--bg-semi-transparent-normal);
  }

  &.bg-hard {
    border-color: var(--bg-hard);
    background: var(--bg-semi-transparent-hard);
  }

  &.bg-expert {
    border-color: var(--bg-expert);
    background: var(--bg-semi-transparent-expert);
  }

  &.bg-expertPlus {
    border-color: var(--bg-expertPlus);
    background: var(--bg-semi-transparent-expertPlus);
  }

  .cover img {
    border-radius: 17px;
    width: 94px;
    height: 94px;
    margin-left: 16px;
  }

  .details {
    width: 100%;
    margin-left: 15px;
    padding-right: 18px;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;

        background-color: #fff;
        padding: 8px 10px;
        border-radius: 8px;

        width: fit-content;
        max-width: 180px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .key {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 13px;

        margin-top: -10px;

        text-align: right;
        color: #ffffff;
      }
    }

    .info {
      display: flex;

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;

        margin-top: 5px;

        color: rgba(255, 255, 255, 0.75);

        flex: 1;
      }

      .difficulty {
        display: flex;
        align-items: flex-end;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;

          padding: 7px 9px;
          border-radius: 8px;

          &.bg-easy {
            background-color: var(--bg-easy);
          }

          &.bg-normal {
            background-color: var(--bg-normal);
          }

          &.bg-hard {
            background-color: var(--bg-hard);
          }

          &.bg-expert {
            background-color: var(--bg-expert);
          }

          &.bg-expertPlus {
            background-color: var(--bg-expertPlus);
          }
        }
      }
    }
  }
}
</style>
