<template>
  <div class="embeds">
    <div class="details">
      <div
        v-for="player in overlayPlayers"
        :key="player.participantID"
        class="playerDetails"
        :class="{
          p1: player.team === 'p1',
          p2: player.team === 'p2',
          missed: missesPerPlayer[player.scoresaber]
            ? missesPerPlayer[player.scoresaber].showAnimation
            : false,
          mvp: mvp === player.scoresaber,
        }"
      >
        <div class="profile">
          <div class="avatar">
            <img
              v-image-fall-back
              :src="`https://new.scoresaber.com${player.pictureUrl}`"
            />
            <svg
              width="424"
              height="464"
              viewBox="0 0 424 464"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="hexagon"
            >
              <path
                d="M51.52 98.1301L176.43 26.0103C187.223 19.7787 199.467 16.498 211.93 16.498C224.393 16.498 236.637 19.7787 247.43 26.0103L372.34 98.1301C383.152 104.358 392.132 113.326 398.375 124.13C404.618 134.934 407.903 147.192 407.9 159.67V303.9C407.904 316.371 404.623 328.622 398.387 339.422C392.152 350.222 383.182 359.188 372.38 365.42L247.47 437.54C236.677 443.772 224.433 447.052 211.97 447.052C199.507 447.052 187.263 443.772 176.47 437.54L51.52 365.4C40.7182 359.168 31.7483 350.202 25.5128 339.402C19.2774 328.602 15.9964 316.351 16 303.88V159.67C15.9929 147.196 19.2722 134.941 25.5078 124.137C31.7434 113.334 40.7153 104.364 51.52 98.1301V98.1301Z"
                stroke="white"
                stroke-width="32"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="name">{{ player.name }}</div>
        </div>
        <div
          v-if="
            taMatch &&
            taMatch.associated_users.find(
              (user) => user.user_id == player.scoresaber
            )
          "
          class="score"
        >
          <transition name="combo-transition" mode="out-in">
            <div
              :key="
                taMatch.associated_users.find(
                  (user) => user.user_id == player.scoresaber
                ).combo
              "
              class="combo"
            >
              {{
                taMatch.associated_users.find(
                  (user) => user.user_id == player.scoresaber
                ).combo + "x "
              }}
              <span
                v-if="
                  taMatch.associated_users.find(
                    (user) => user.user_id == player.scoresaber
                  ).misses === 0
                "
                class="fc"
              >
                FC
              </span>
              <span
                v-else-if="
                  taMatch.associated_users.find(
                    (user) => user.user_id == player.scoresaber
                  ).misses > 0
                "
                class="fc miss"
              >
                {{
                  taMatch.associated_users.find(
                    (user) => user.user_id == player.scoresaber
                  ).misses
                }}x
              </span>
            </div>
          </transition>
          <div class="accuracy">
            {{
              Math.round(
                taMatch.associated_users.find(
                  (user) => user.user_id == player.scoresaber
                ).accuracy * 10000
              ) / 100
            }}%
          </div>
        </div>
        <div class="mvp-label">MVP</div>
      </div>
    </div>
    <div class="players">
      <div
        class="player"
        v-for="player in overlayPlayers"
        :key="player.participantID"
      >
        <div class="wrapper">
          <img
            v-if="unmutedPlayer === player.streamUrl"
            :class="`sound-${player.team}`"
            src="../assets/sound.svg"
          />
          <iframe
            frameborder="0"
            width="100%"
            height="100%"
            :src="
              'https://player.twitch.tv/?enableExtensions=false&muted=' +
              (unmutedPlayer !== player.streamUrl) +
              '&!scrolling&channel=' +
              player.streamUrl.replace('https://www.twitch.tv/', '') +
              '&height=1080&parent=twitch.tv&parent=overlay.beatsaberworldcup.com&parent=localhost&player=popout&volume=1&width=1920&refresh=' +
              refreshPlayers.includes(player.participantID)
            "
            allowfullscreen
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Players",
  data() {
    return {
      taMatch: null,
      missesPerPlayer: {},
      unmutedPlayer: "",
      refreshPlayers: [],
    };
  },
  sockets: {
    resetOverlay() {
      this.missesPerPlayer = {};
    },
    unmuteTwitch(data) {
      this.unmutedPlayer = data.url;
    },
    refreshPlayer(data) {
      let player = this.overlayPlayers.find(
        (player) => player.streamUrl === data.url
      );
      if (this.refreshPlayers.includes(player.participantID)) {
        this.refreshPlayers = this.refreshPlayers.filter(
          (pl) => player.participantID !== pl
        );
      } else {
        this.refreshPlayers.push(player.participantID);
      }
    },
    data(res) {
      if (res.type === "taMatch") {
        this.taMatch = res.data;
        if (!this.taMatch) return;

        for (let player of this.taMatch.associated_users.filter(
          (client) => client.client_type == 0 && client.play_state == 1
        )) {
          if (!this.missesPerPlayer[player.user_id]) {
            this.missesPerPlayer[player.user_id] = {
              showAnimation: false,
              timeout: null,
              misses: 0,
            };
          } else {
            if (this.missesPerPlayer[player.user_id].misses < player.misses) {
              if (this.missesPerPlayer[player.user_id].timeout) {
                clearTimeout(this.missesPerPlayer[player.user_id].timeout);
              }
              this.missesPerPlayer[player.user_id].showAnimation = true;
              this.missesPerPlayer[player.user_id].timeout = setTimeout(() => {
                this.missesPerPlayer[player.user_id].showAnimation = false;
                this.missesPerPlayer[player.user_id].timeout = null;
              }, 600);
            }
            this.missesPerPlayer[player.user_id].misses = player.misses;
          }
        }
      }
    },
  },
  computed: {
    overlayPlayers() {
      return this.$store.state.tournament.currentMatch
        ? [
            ...this.p1Players.slice(0, 2),
            ...this.p2Players.slice(0, 2),
            ...this.p1Players.slice(2, 4),
            ...this.p2Players.slice(2, 4),
          ]
        : [];
    },
    p1Players() {
      return this.$store.state.tournament.currentMatch
        ? this.$store.state.tournament.currentMatch.p1.players.map(
            (player) => ({ ...player, team: "p1" })
          )
        : [];
    },
    p2Players() {
      return this.$store.state.tournament.currentMatch
        ? this.$store.state.tournament.currentMatch.p2.players.map(
            (player) => ({ ...player, team: "p2" })
          )
        : [];
    },
    mvp() {
      if (!this.taMatch) return null;
      let sortedAccuracy = [...this.taMatch.associated_users].sort((a, b) => {
        return b.accuracy - a.accuracy;
      });
      return sortedAccuracy[0].song_position > 60
        ? sortedAccuracy[0].user_id
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
}

.players {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2px;
}

.details {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2px;
  z-index: 5000000;
}

.player {
  width: 478px;
  height: 356px;
  background: red;
  overflow: hidden;
}

.playerDetails {
  width: 478px;
  height: 356px;
  overflow: hidden;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  transition: all 0.3s ease-in-out;

  &.missed {
    box-shadow: inset 0px 0px 29px 15px rgba(255, 0, 0, 0.5);
  }

  .mvp-label {
    opacity: 0;
    transition: all 0.3s ease-in-out;

    position: absolute;
    transform: translate(-192px, -340px) scaleY(0);
    background: rgb(223, 184, 9);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    z-index: 2;

    padding: 2px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.mvp {
    box-shadow: inset 0px 0px 0px 4px rgb(223, 184, 9);

    .mvp-label {
      opacity: 1;
      position: absolute;
      transform: translate(-192px, -322px);
      background: rgb(223, 184, 9);
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      line-height: 1.5;
      z-index: 15;

      padding: 2px 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &.p1 {
    flex-direction: row-reverse;

    .profile {
      flex-direction: row-reverse;
    }
  }

  &.p2 {
    .mvp-label {
      transform: translate(194px, -340px) scaleY(0);
    }

    &.mvp {
      .mvp-label {
        transform: translate(194px, -322px);
      }
    }

    .score {
      text-align: right;
    }
  }

  .score {
    color: white;

    margin: 0 10px 5px 10px;

    .combo {
      font-size: 25px;
      font-weight: bold;
      margin-right: 5px;

      .fc {
        font-size: 16px;

        &.miss {
          font-size: 18px;
          color: rgb(255, 117, 129);
        }
      }
    }
  }

  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0 10px 5px 10px;

    .name {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 2px;
      color: white;
    }

    .avatar {
      img {
        width: 48px;
        height: 48px;
        clip-path: polygon(50% 5%, 90% 28%, 90% 72%, 50% 95%, 10% 72%, 10% 28%);
      }

      .hexagon {
        width: 48px;
        height: 48px;
        position: relative;
        margin-left: -48px;
      }
    }
  }
}

iframe {
  height: 100%;
  width: 133%;
  margin-left: -75px;
  aspect-ratio: 16 / 9;
}

.combo-transition-enter-active {
  transition: all 0.4s ease;
}
.combo-transition-leave-active {
  transition: all 0.17s cubic-bezier(1, 0.5, 0.8, 1);
}
.combo-transition-enter, .combo-transition-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  font-size: 31px !important;
}

.sound-p1 {
  position: absolute;
  width: 38px;
  margin-top: 10px;
  margin-left: 13px;
}

.sound-p2 {
  position: absolute;
  width: 38px;
  margin-top: 10px;
  margin-left: 428px;
}
</style>
