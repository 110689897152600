<template>
  <div v-if="$store.state.tournament.currentMatch" class="team-players">
    <div
      v-if="mapCurrentVer"
      class="map-showcase"
      :style="`--coverURL: url(${mapCurrentVer.coverURL}); --bg-opacity: ${
        showcase ? 1 : 0
      };`"
    >
      <div class="background" ref="showcase"></div>
      <transition name="fade" mode="out-in">
        <div v-if="showcase" class="map-info">
          <div class="map-name">{{ map.map_name }}</div>
          <div class="map-artist">{{ map.map_artist }}</div>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div v-if="showcase" class="chart-info">
          <div class="content">
            Map Key <span class="tag">{{ map.map_key }}</span
            ><br />
            Mapper <span class="tag">{{ map.map_mapper }}</span
            ><br />
            BPM <span class="tag">{{ map.BeatSaver.metadata.bpm }}</span
            ><br />
            Song Length <span class="tag">{{ songLength }}</span
            ><br />
            Difficulty
            <span class="tag" :class="colorClass">{{ map.display_diff }}</span
            ><br />
          </div>
        </div>
      </transition>
      <div class="cover">
        <img :src="`${mapCurrentVer.coverURL}`" />
        <svg
          width="424"
          height="464"
          viewBox="0 0 424 464"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="hexagon"
        >
          <path
            d="M51.52 98.1301L176.43 26.0103C187.223 19.7787 199.467 16.498 211.93 16.498C224.393 16.498 236.637 19.7787 247.43 26.0103L372.34 98.1301C383.152 104.358 392.132 113.326 398.375 124.13C404.618 134.934 407.903 147.192 407.9 159.67V303.9C407.904 316.371 404.623 328.622 398.387 339.422C392.152 350.222 383.182 359.188 372.38 365.42L247.47 437.54C236.677 443.772 224.433 447.052 211.97 447.052C199.507 447.052 187.263 443.772 176.47 437.54L51.52 365.4C40.7182 359.168 31.7483 350.202 25.5128 339.402C19.2774 328.602 15.9964 316.351 16 303.88V159.67C15.9929 147.196 19.2722 134.941 25.5078 124.137C31.7434 113.334 40.7153 104.364 51.52 98.1301V98.1301Z"
            stroke="white"
            stroke-width="32"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div v-for="team of ['p1', 'p2']" :key="team" :class="team">
      <div class="playing">
        <div
          v-for="player in $store.state.tournament.currentMatch[team].players"
          :key="player.scoresaber"
          class="player"
        >
          <div class="avatar">
            <img
              v-image-fall-back
              :src="`https://new.scoresaber.com${player.pictureUrl}`"
            />
            <svg
              width="424"
              height="464"
              viewBox="0 0 424 464"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="hexagon"
            >
              <path
                d="M51.52 98.1301L176.43 26.0103C187.223 19.7787 199.467 16.498 211.93 16.498C224.393 16.498 236.637 19.7787 247.43 26.0103L372.34 98.1301C383.152 104.358 392.132 113.326 398.375 124.13C404.618 134.934 407.903 147.192 407.9 159.67V303.9C407.904 316.371 404.623 328.622 398.387 339.422C392.152 350.222 383.182 359.188 372.38 365.42L247.47 437.54C236.677 443.772 224.433 447.052 211.97 447.052C199.507 447.052 187.263 443.772 176.47 437.54L51.52 365.4C40.7182 359.168 31.7483 350.202 25.5128 339.402C19.2774 328.602 15.9964 316.351 16 303.88V159.67C15.9929 147.196 19.2722 134.941 25.5078 124.137C31.7434 113.334 40.7153 104.364 51.52 98.1301V98.1301Z"
                stroke="white"
                stroke-width="32"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="name">
            {{ player.name }}
          </div>
        </div>
      </div>

      <div class="not-playing">
        <div
          v-for="player in $store.state.tournament.currentMatch[
            team
          ].team.teamMembers.filter(
            (member) =>
              $store.state.tournament.currentMatch[team].players.findIndex(
                (p) => p.scoresaber === member.scoresaber
              ) === -1
          )"
          :key="player.scoresaber"
          class="player"
        >
          <div class="avatar">
            <img
              v-image-fall-back
              :src="`https://new.scoresaber.com${player.pictureUrl}`"
            />
            <svg
              width="424"
              height="464"
              viewBox="0 0 424 464"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="hexagon"
            >
              <path
                d="M51.52 98.1301L176.43 26.0103C187.223 19.7787 199.467 16.498 211.93 16.498C224.393 16.498 236.637 19.7787 247.43 26.0103L372.34 98.1301C383.152 104.358 392.132 113.326 398.375 124.13C404.618 134.934 407.903 147.192 407.9 159.67V303.9C407.904 316.371 404.623 328.622 398.387 339.422C392.152 350.222 383.182 359.188 372.38 365.42L247.47 437.54C236.677 443.772 224.433 447.052 211.97 447.052C199.507 447.052 187.263 443.772 176.47 437.54L51.52 365.4C40.7182 359.168 31.7483 350.202 25.5128 339.402C19.2774 328.602 15.9964 316.351 16 303.88V159.67C15.9929 147.196 19.2722 134.941 25.5078 124.137C31.7434 113.334 40.7153 104.364 51.52 98.1301V98.1301Z"
                stroke="white"
                stroke-width="32"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="name">
            {{ player.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamPlayers",
  data() {
    return {
      showcase: false,
      map: null,
      cssAnimation: false,
    };
  },
  computed: {
    colorClass() {
      return `bg-${this.map.map_diff}`;
    },
    currentMap() {
      return this.$store.state.tournament.currentMap;
    },
    mapCurrentVer() {
      return this.map
        ? this.map.BeatSaver.versions.find((ver) => ver.state === "Published")
        : {};
    },
    songLength() {
      return `${Math.floor(this.map.BeatSaver.metadata.duration / 60)}:${
        this.map.BeatSaver.metadata.duration % 60 < 10 ? "0" : ""
      }${this.map.BeatSaver.metadata.duration % 60}`;
    },
  },
  watch: {
    currentMap(newMap) {
      this.$refs.showcase.style.animation = "none";
      this.$refs.showcase.offsetWidth;
      this.$refs.showcase.style.animation = null;
      this.map = newMap;
      this.showcase = true;
      let previewURL = this.map.BeatSaver.versions.find(
        (version) => version.state === "Published"
      ).previewURL;
      let a = new Audio(previewURL);
      a.volume = 0;
      a.play();
      const fadeAudioIn = setInterval(() => {
        a.volume += 0.01;
        if (a.volume > 0.5) {
          clearInterval(fadeAudioIn);
        }
      }, 50);
      setTimeout(() => {
        this.showcase = false;
      }, 5000);
      const fadeAudioOut = setInterval(() => {
        const fadePoint = a.duration - 3;
        if (a.currentTime >= fadePoint && a.volume !== 0) {
          a.volume -= 0.01;
        }

        if (a.volume < 0.003) {
          clearInterval(fadeAudioOut);
        }
      }, 55);
    },
  },
};
</script>

<style lang="scss" scoped>
.team-players {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 140px;
  margin-top: 55px;

  .map-showcase {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #0000003f var(--coverURL) center center;
      background-size: cover;
      filter: blur(25px) brightness(50%);
      transform: scale(1.3);
      transition: opacity 0.5s;
      opacity: var(--bg-opacity);
      animation: moving-bg 45s linear infinite;
    }

    @keyframes moving-bg {
      0% {
        background-position-x: 50%;
        background-position-y: 35%;
      }
      50% {
        background-position-x: 50%;
        background-position-y: 75%;
      }
      100% {
        background-position-x: 50%;
        background-position-y: 35%;
      }
    }

    .map-info {
      position: absolute;
      top: 0;
      left: 200px;
      height: 95%;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .map-name {
        font-size: 3.5rem;
        font-weight: bold;
        font-style: italic;
        color: #fff;
        line-height: 3.4rem;

        width: 430px;
      }

      .map-artist {
        font-size: 1.5rem;
        color: #fff;
        margin-top: 10px;
      }
    }

    .chart-info {
      position: absolute;
      top: 0;
      right: 200px;
      height: 95%;
      text-align: right;

      display: flex;
      flex-direction: column;
      justify-content: center;

      font-size: 1.5rem;
      color: #fff;
      margin-top: 10px;

      .content {
        line-height: 3.5rem;

        .tag {
          padding: 5px 10px;
          border-radius: 15px;
          margin-left: 5px;
          background: #fff;
          color: #000;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.5rem;

          --bg-easy: #14ff00;
          --bg-normal: #00b2ff;
          --bg-hard: #ffa800;
          --bg-expert: #ff0000;
          --bg-expertPlus: #eb00ff;

          &.bg-easy {
            background: var(--bg-easy);
            color: #fff;
          }

          &.bg-normal {
            background: var(--bg-normal);
            color: #fff;
          }

          &.bg-hard {
            background: var(--bg-hard);
            color: #fff;
          }

          &.bg-expert {
            background: var(--bg-expert);
            color: #fff;
          }

          &.bg-expertPlus {
            background: var(--bg-expertPlus);
            color: #fff;
          }
        }
      }
    }

    .cover {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 328px;

      --coverSize: 375px;

      img {
        width: var(--coverSize);
        height: var(--coverSize);
        clip-path: polygon(50% 5%, 90% 28%, 90% 72%, 50% 95%, 10% 72%, 10% 28%);
      }

      .hexagon {
        width: var(--coverSize);
        height: var(--coverSize);
        position: absolute;
      }
    }
  }

  .playing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;

    .player {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .name {
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        color: white;
      }

      .avatar {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 220px;
          height: 220px;
          clip-path: polygon(
            50% 5%,
            90% 28%,
            90% 72%,
            50% 95%,
            10% 72%,
            10% 28%
          );
        }

        .hexagon {
          width: 220px;
          height: 220px;
          position: absolute;
        }
      }
    }
  }

  .not-playing {
    margin-top: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    gap: 25px;

    .player {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .name {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        color: white;
      }

      .avatar {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 100px;
          height: 100px;
          clip-path: polygon(
            50% 5%,
            90% 28%,
            90% 72%,
            50% 95%,
            10% 72%,
            10% 28%
          );
        }

        .hexagon {
          width: 100px;
          height: 100px;
          position: absolute;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
