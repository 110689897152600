import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tournament: {
      bracket: null,
      pools: null,

      prizePool: null,

      taUsers: [],
      taMatch: null,

      currentMap: null,
      currentMatch: null,

      currentMapPool: null,
    },
  },
});
