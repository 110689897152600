<template>
  <div class="picks-and-bans">
    <div class="maps">
      <Map
        v-for="map in $store.state.tournament.currentMapPool.maps"
        :key="map.map_key"
        :map="map"
        :played="
          $store.state.tournament.currentMatch.scores.find(
            (score) => score.map_id == map.map_id
          )
        "
        :banned="
          $store.state.tournament.currentMatch.bans.find(
            (ban) => ban.map_key === map.map_key
          ) &&
          !$store.state.tournament.currentMatch.scores.find(
            (score) => score.map_id == map.map_id
          )
        "
        :picked="
          $store.state.tournament.currentMatch.picks.find(
            (pick) => pick.map_key === map.map_key
          ) &&
          !$store.state.tournament.currentMatch.scores.find(
            (score) => score.map_id == map.map_id
          )
        "
        :tiebreaker="
          !$store.state.tournament.currentMatch.picks.find(
            (pick) => pick.map_key === map.map_key
          ) &&
          !$store.state.tournament.currentMatch.bans.find(
            (ban) => ban.map_key === map.map_key
          ) &&
          !$store.state.tournament.currentMatch.scores.find(
            (score) => score.map_id == map.map_id
          ) &&
          $store.state.tournament.currentMatch.picks.length +
            $store.state.tournament.currentMatch.bans.length ===
            $store.state.tournament.currentMapPool.maps.length - 1
        "
      />
    </div>
  </div>
</template>

<script>
import Map from "./Map.vue";

export default {
  name: "PicksAndBans",
  components: { Map },
  computed: {
    currentMap() {
      return this.$store.state.tournament.currentMap;
    },
  },
  sockets: {
    transitionOBS() {
      window.obsstudio.setCurrentScene("Player Rosters");
    },
  },
};
</script>

<style lang="scss" scoped>
.picks-and-bans {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & .maps {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
}
</style>
