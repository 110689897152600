<template>
  <Map
    v-if="$store.state.tournament.currentMap"
    :map="$store.state.tournament.currentMap"
  />
</template>

<script>
import Map from "./Map.vue";

export default {
  name: "CurrentMap",
  components: { Map },
};
</script>

<style scoped lang="scss"></style>
