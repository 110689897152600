<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  sockets: {
    data(res) {
      this.$store.state.tournament[res.type] = res.data;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html,
body {
  background: #4f4f4f;
  margin: 0 0;
  overflow: hidden;

  font-family: "Plus Jakarta Sans", sans-serif;
}
</style>
