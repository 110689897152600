<template>
  <div class="bracket">
    <div class="day">
      <div class="text">
        {{
          ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
            new Date().getDay()
          ].toUpperCase()
        }}
      </div>
      <div class="number">{{ new Date().getDate() }}</div>
    </div>
    <div v-if="$store.state.tournament.currentMatch" class="round-name">
      {{ $store.state.tournament.currentMatch.roundName }}
    </div>
    <div class="bracket-grid">
      <transition name="fade" mode="out-in" tag="div" class="bracket">
        <winners-bracket
          v-if="bracketShown === 'winners'"
          :bracket="bracket"
        ></winners-bracket>
        <losers-bracket v-else :bracket="bracket"></losers-bracket>
      </transition>
    </div>
  </div>
</template>

<script>
import WinnersBracket from "./WinnersBracket.vue";
import LosersBracket from "./LosersBracket.vue";
export default {
  components: { WinnersBracket, LosersBracket },
  computed: {
    bracket() {
      return this.$store.state.tournament.bracket
        ? this.$store.state.tournament.bracket.bracket
        : [];
    },
    bracketShown() {
      return this.$store.state.tournament.currentMatch
        ? this.$store.state.tournament.currentMatch.round >= 0
          ? "winners"
          : "losers"
        : "winners";
    },
    currentMap() {
      return this.$store.state.tournament.currentMap;
    },
  },
  sockets: {
    transitionOBS() {
      window.obsstudio.setCurrentScene("Player Rosters");
    },
  },
};
</script>

<style lang="scss" scoped>
.bracket-grid {
  position: absolute;
  left: 308px;
  top: 121px;
  height: calc(100% - 165px);

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.round-name {
  position: absolute;
  left: 560px;
  top: 40px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000;

  background: white;
  border-radius: 13px;
  padding: 12px 18px;

  width: fit-content;
}

.day {
  background-color: #fff;
  width: 225px;
  height: 225px;
  border-radius: 50px;
  margin-left: 43px;
  margin-top: 40px;

  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    text-align: center;

    color: #959595;
    padding-top: 50px;
  }

  .number {
    font-style: normal;
    font-weight: 901;
    font-size: 96px;
    line-height: 115px;
    text-align: center;

    color: #000000;
    margin-top: -10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
