<template>
  <svg
    width="2410"
    height="746"
    viewBox="0 0 2410 746"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="[{ 'margin-left': roundMargin }]"
  >
    <path
      d="M260 34.0413H334.898C341 34.0413 352.994 37.3062 352.994 56.3022C352.994 75.2982 352.994 104.782 352.994 117.149C353.162 121.766 349.777 131 334.898 131C320.019 131 278.766 131 260 131"
      stroke="white"
    />
    <path d="M353.5 81.5H429.5" stroke="#E7E7E7" />
    <foreignObject y="97" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 0)[1]" />
    </foreignObject>

    <foreignObject width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 0)[0]" />
    </foreignObject>
    <path
      d="M260 228.041H334.898C341 228.041 352.994 231.306 352.994 250.302C352.994 269.298 352.994 298.782 352.994 311.149C353.162 315.766 349.777 325 334.898 325C320.019 325 278.766 325 260 325"
      stroke="white"
    />
    <path d="M353.5 275.5H429.5" stroke="#E7E7E7" />
    <foreignObject y="291" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 0)[3]" />
    </foreignObject>
    <foreignObject y="194" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 0)[2]" />
    </foreignObject>
    <path
      d="M260 422.041H334.898C341 422.041 352.994 425.306 352.994 444.302C352.994 463.298 352.994 492.782 352.994 505.149C353.162 509.766 349.777 519 334.898 519C320.019 519 278.766 519 260 519"
      stroke="white"
    />
    <path d="M353.5 469.5H429.5" stroke="#E7E7E7" />
    <foreignObject y="485" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 0)[5]" />
    </foreignObject>
    <foreignObject y="388" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 0)[4]" />
    </foreignObject>
    <path
      d="M260 616.041H334.898C341 616.041 352.994 619.306 352.994 638.302C352.994 657.298 352.994 686.782 352.994 699.149C353.162 703.766 349.777 713 334.898 713C320.019 713 278.766 713 260 713"
      stroke="white"
    />
    <path d="M353.5 663.5H429.5" stroke="#E7E7E7" />
    <path d="M1546 369H1718" stroke="#E7E7E7" />
    <path d="M1978 369H2150" stroke="#E7E7E7" />
    <foreignObject y="582" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 0)[6]" />
    </foreignObject>
    <foreignObject y="679" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 0)[7]" />
    </foreignObject>
    <path
      d="M689 471L741.5 471C774 471 781.994 478.5 781.994 512.5C781.994 546.5 781.994 611.811 781.994 636.429C781.994 651 770.5 664 747.5 664C732.621 664 707.766 664 689 664"
      stroke="white"
    />
    <path d="M782.5 565.468H858.5" stroke="#E7E7E7" />
    <foreignObject x="429" y="630" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 1)[3]" />
    </foreignObject>
    <foreignObject x="429" y="436" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 1)[2]" />
    </foreignObject>
    <path
      d="M689 80L741.5 80C774 80 781.994 87.5 781.994 121.5C781.994 155.5 781.994 220.811 781.994 245.429C781.994 260 770.5 273 747.5 273C732.621 273 707.766 273 689 273"
      stroke="white"
    />
    <path d="M782.5 174.468H858.5" stroke="#E7E7E7" />
    <foreignObject x="429" y="239" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 1)[1]" />
    </foreignObject>
    <foreignObject x="429" y="45" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 1)[0]" />
    </foreignObject>
    <foreignObject x="857" y="139" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 2)[0]" />
    </foreignObject>
    <foreignObject x="1286" y="335" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 3)[0]" />
    </foreignObject>
    <!-- <rect x="1718" y="335" width="260" height="67" rx="11" fill="white" /> -->
    <foreignObject x="1718" y="335" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 4)[0]" />
    </foreignObject>
    <!-- <rect x="2150" y="335" width="260" height="67" rx="11" fill="white" /> -->
    <foreignObject x="2150" y="335" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 5)[0]" />
    </foreignObject>

    <foreignObject x="857" y="532" width="260" height="67" rx="11">
      <bracket-slot :match="bracket.filter((match) => match.round === 2)[1]" />
    </foreignObject>
    <path
      d="M1117 173L1169.5 173C1210 173 1210 188.272 1210 257.505C1210 326.738 1210 459.73 1210 509.857C1210 539.528 1210 566 1175.5 566C1160.62 566 1135.77 566 1117 566"
      stroke="white"
    />
    <path d="M1210 369H1286" stroke="#E7E7E7" />
  </svg>
</template>

<script>
import BracketSlot from "./BracketSlot.vue";
export default {
  components: { BracketSlot },
  props: ["bracket"],
  computed: {
    roundMargin() {
      let currentMatch = this.$store.state.tournament.currentMatch;
      if (currentMatch) {
        if (currentMatch.round === 2) {
          return "-429px";
        } else if (currentMatch.round === 3) {
          return "-857px";
        } else if (currentMatch.round >= 4) {
          return "-1286px";
        }
      }
      return "0px";
    },
  },
};
</script>

<style scoped>
svg {
  transition: margin-left 1.5s ease-in-out;
}
</style>
