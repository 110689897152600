<template>
  <div class="result">
    <div
      v-if="
        this.$store.state.tournament.currentMapPool &&
        this.$store.state.tournament.currentMatch.scores
      "
      class="maps"
    >
      <div
        v-for="score in this.$store.state.tournament.currentMatch.scores"
        :key="score.map_id"
        class="last-played"
      >
        <img class="map" :src="getMap(score.map_id).cover_url" />
        <div class="name">
          <span>{{ getMap(score.map_id).map_name }}</span>
        </div>
        <div class="details" :class="`bg-${getMap(score.map_id).map_diff}`">
          <div class="diff">{{ getMap(score.map_id).display_diff }}</div>
          <marquee class="info"
            >Key: {{ getMap(score.map_id).map_key }} - Mapped by
            {{ getMap(score.map_id).map_mapper }}
          </marquee>
        </div>
        <div class="won-by">
          <img
            :src="
              getCountryImage(
                score.p1_score > score.p2_score
                  ? $store.state.tournament.currentMatch.p1.country
                  : $store.state.tournament.currentMatch.p2.country
              )
            "
          />
        </div>
      </div>
    </div>

    <div class="countries">
      <div class="country">
        <div class="details winner">
          <img :src="getCountryImage(winner.country)" class="flag" />
          <span class="name">{{ winner.name }}</span>
          <span class="score">{{ winner.score }}</span>
          <span class="winner">Winner</span>
        </div>
        <div class="players">
          <div
            v-for="player in winner.team.teamMembers"
            :key="player.scoresaber"
            class="player"
          >
            <div class="avatar">
              <img
                v-image-fall-back
                :src="`https://new.scoresaber.com${player.pictureUrl}`"
              />
              <svg
                width="424"
                height="464"
                viewBox="0 0 424 464"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="hexagon"
              >
                <path
                  d="M51.52 98.1301L176.43 26.0103C187.223 19.7787 199.467 16.498 211.93 16.498C224.393 16.498 236.637 19.7787 247.43 26.0103L372.34 98.1301C383.152 104.358 392.132 113.326 398.375 124.13C404.618 134.934 407.903 147.192 407.9 159.67V303.9C407.904 316.371 404.623 328.622 398.387 339.422C392.152 350.222 383.182 359.188 372.38 365.42L247.47 437.54C236.677 443.772 224.433 447.052 211.97 447.052C199.507 447.052 187.263 443.772 176.47 437.54L51.52 365.4C40.7182 359.168 31.7483 350.202 25.5128 339.402C19.2774 328.602 15.9964 316.351 16 303.88V159.67C15.9929 147.196 19.2722 134.941 25.5078 124.137C31.7434 113.334 40.7153 104.364 51.52 98.1301V98.1301Z"
                  stroke="white"
                  stroke-width="32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="name">
              {{ player.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="country">
        <div class="details loser">
          <img :src="getCountryImage(loser.country)" class="flag" />
          <span class="name">{{ loser.name }}</span>
          <span class="score">{{ loser.score }}</span>
          <span class="loser">Loser</span>
        </div>
        <div class="players">
          <div
            v-for="player in loser.team.teamMembers"
            :key="player.scoresaber"
            class="player"
          >
            <div class="avatar">
              <img
                v-image-fall-back
                :src="`https://new.scoresaber.com${player.pictureUrl}`"
              />
              <svg
                width="424"
                height="464"
                viewBox="0 0 424 464"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="hexagon"
              >
                <path
                  d="M51.52 98.1301L176.43 26.0103C187.223 19.7787 199.467 16.498 211.93 16.498C224.393 16.498 236.637 19.7787 247.43 26.0103L372.34 98.1301C383.152 104.358 392.132 113.326 398.375 124.13C404.618 134.934 407.903 147.192 407.9 159.67V303.9C407.904 316.371 404.623 328.622 398.387 339.422C392.152 350.222 383.182 359.188 372.38 365.42L247.47 437.54C236.677 443.772 224.433 447.052 211.97 447.052C199.507 447.052 187.263 443.772 176.47 437.54L51.52 365.4C40.7182 359.168 31.7483 350.202 25.5128 339.402C19.2774 328.602 15.9964 316.351 16 303.88V159.67C15.9929 147.196 19.2722 134.941 25.5078 124.137C31.7434 113.334 40.7153 104.364 51.52 98.1301V98.1301Z"
                  stroke="white"
                  stroke-width="32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="name">
              {{ player.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    winner() {
      return this.$store.state.tournament.currentMatch.p1.score >
        this.$store.state.tournament.currentMatch.p2.score
        ? this.$store.state.tournament.currentMatch.p1
        : this.$store.state.tournament.currentMatch.p2;
    },
    loser() {
      return this.$store.state.tournament.currentMatch.p1.score >
        this.$store.state.tournament.currentMatch.p2.score
        ? this.$store.state.tournament.currentMatch.p2
        : this.$store.state.tournament.currentMatch.p1;
    },
  },
  methods: {
    getMap(mapId) {
      return this.$store.state.tournament.currentMapPool.maps.find(
        (map) => map.map_id == mapId
      );
    },
    getCountryImage(country) {
      var images = require.context("../assets/flags/", false, /\.png$/);
      return images("./" + country.toUpperCase() + ".png");
    },
  },
};
</script>

<style scoped lang="scss">
.result {
  .maps {
    position: absolute;
    left: 82px;
    top: 190px;

    width: 580px;

    .last-played {
      display: flex;
      margin-bottom: 10px;

      width: 100%;

      --height: 38px;

      gap: 10px;

      .map {
        width: var(--height);
        height: var(--height);

        background: #969696;
        border-radius: 10px;
      }

      .won-by {
        display: flex;
        align-items: center;

        img {
          height: var(--height);
        }
      }

      .name {
        display: flex;
        align-items: center;

        background-color: white;
        border-radius: 10px;

        height: var(--height);
        font-size: 16px;

        font-style: normal;
        font-weight: 600;

        padding: 0 12px;

        span {
          width: fit-content;
          max-width: 220px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .details {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-right: 12px;

        height: var(--height);
        border-style: solid;
        border-width: 2px;
        border-radius: 10px;

        flex: 1;

        color: white;

        --bg-easy: #14ff00;
        --bg-normal: #00b2ff;
        --bg-hard: #ffa800;
        --bg-expert: #ff0000;
        --bg-expertPlus: #eb00ff;

        .diff {
          padding: 0 12px;
          height: 100%;
          display: flex;
          align-items: center;

          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          color: black;

          font-style: normal;
          font-weight: 600;
        }

        &.bg-easy {
          border-color: var(--bg-easy);
          .diff {
            background-color: var(--bg-easy);
          }
        }

        &.bg-normal {
          border-color: var(--bg-normal);
          .diff {
            background-color: var(--bg-normal);
          }
        }

        &.bg-hard {
          border-color: var(--bg-hard);
          .diff {
            background-color: var(--bg-hard);
          }
        }

        &.bg-expert {
          border-color: var(--bg-expert);
          .diff {
            background-color: var(--bg-expert);
          }
        }

        &.bg-expertPlus {
          border-color: var(--bg-expertPlus);
          .diff {
            background-color: var(--bg-expertPlus);
          }
        }
      }
    }
  }

  .countries {
    position: absolute;
    right: 75px;
    top: 65px;

    width: 600px;

    .country {
      margin-bottom: 35px;
      .details {
        display: flex;
        align-items: center;

        margin-left: 16px;
        margin-bottom: 2rem;

        gap: 8px;

        .flag {
          width: 64px;
        }

        > .name {
          font-style: normal;
          font-weight: 800;
          font-size: 26px;
          line-height: 26px;
          color: #000;
          background-color: white;
          padding: 10px 12px;
          border-radius: 12px;
        }

        .score {
          font-style: normal;
          font-weight: 900;
          font-size: 26px;
          line-height: 26px;
          color: #fff;
          margin-left: 5px;
          margin-right: 5px;
        }

        &.loser {
          opacity: 0.4;
        }

        .winner {
          color: #00ff38;
          border: 2px solid #00ff38;
          border-radius: 8px;
          padding: 4px 8px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }

        .loser {
          color: #838383;
          border: 2px solid #838383;
          border-radius: 8px;
          padding: 4px 8px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .players {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 25px;

        .player {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          .name {
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            color: white;
          }

          .avatar {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 100px;
              height: 100px;
              clip-path: polygon(
                50% 5%,
                90% 28%,
                90% 72%,
                50% 95%,
                10% 72%,
                10% 28%
              );
            }

            .hexagon {
              width: 100px;
              height: 100px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}
</style>
